import * as SentryVue from '@sentry/vue'
import * as SentryBrowser from '@sentry/browser'
import { SENTRY_DSN, ENVIRONMENT, IS_PRODUCTION } from '../../constants'

function getRestaurantId() {
  return localStorage.getItem('je.pcweb.restaurant_id')
}
const SINGLE_SPA_ROUTING_EVENT = 'single-spa:before-routing-event'
let isPageLoadNavigation = true

function initCustomRoutingInstrumentation() {
  if (window.self !== window.top) return false

  window.addEventListener(
    SINGLE_SPA_ROUTING_EVENT,
    ({ detail: { appsByNewStatus, newUrl, navigationIsCanceled } }) => {
      const { pathname } = new URL(newUrl)
      const payload = {
        name: pathname || newUrl,
        attributes: {
          appsByNewStatus,
          navigationIsCanceled,
          'routing.instrumentation': 'single-spa-router',
        },
      }

      if (isPageLoadNavigation) {
        SentryBrowser.startBrowserTracingPageLoadSpan(
          SentryBrowser.getClient(),
          {
            ...payload,
            op: 'pageload',
          }
        )
      } else {
        SentryBrowser.startBrowserTracingNavigationSpan(
          SentryBrowser.getClient(),
          {
            ...payload,
            op: 'navigation',
          }
        )
      }

      isPageLoadNavigation = false
    }
  )
}

const DEFAULT_OPTIONS = {
  dsn: SENTRY_DSN,
  autoSessionTracking: false,
  tracesSampleRate: 0,
  maxBreadcrumbs: 50,
  normalizeDepth: 5,
  environment: ENVIRONMENT,
  trackComponents: true,
}

let isSentryInitialized = false
export const LEVEL = { Error: 'error', Info: 'info' }

function getCookie(name) {
  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    const [key, value] = cookie.trim().split('=')
    if (key === name) {
      return decodeURIComponent(value)
    }
  }
  return null
}

function defaultBefore(event) {
  if (window.self !== window.top) return null

  if (IS_PRODUCTION) {
    if (getCookie('analyticalConsent') === 'false') {
      return null
    }

    return event
  }

  return null
}

function createIntegration(sentrySelected, router) {
  const integrations = !!router
    ? [sentrySelected.browserTracingIntegration({ router })]
    : [
        sentrySelected.browserTracingIntegration({
          instrumentNavigation: false,
          instrumentPageLoad: false,
        }),
      ]
  return { integrations }
}

export function initSentry(sentryOptions = DEFAULT_OPTIONS, router = null) {
  try {
    if (isSentryInitialized) return
    const hasRouter = router ? true : false
    const sentrySelected = hasRouter ? SentryVue : SentryBrowser

    const { beforeSend, ...configOptions } = sentryOptions

    const combinedBeforeSend = (event) => {
      const customResult = defaultBefore(event)

      return beforeSend ? beforeSend(customResult) : customResult
    }

    const { integrations } = createIntegration(sentrySelected, router)

    sentrySelected.init({
      integrations: [...integrations, sentrySelected.replayIntegration()],
      ...configOptions,
      beforeSend: combinedBeforeSend,
      beforeSendSpan: defaultBefore,
      beforeSendTransaction: defaultBefore,
    })

    if (!hasRouter) {
      initCustomRoutingInstrumentation()
    }

    isSentryInitialized = true
  } catch (error) {
    throw new Error(error)
  }
}

export function makeSentryScope({
  packageName = '',
  release = '0.0.1',
  sentryOptions = {},
  router = null,
}) {
  const { dsn, beforeSend } = sentryOptions
  const sentrySelected = !!router ? SentryVue : SentryBrowser

  const combinedBeforeSend = (event) => {
    const customResult = defaultBefore(event)

    return beforeSend ? beforeSend(customResult) : customResult
  }

  const preparedSentryOptions = {
    ...DEFAULT_OPTIONS,
    transport: sentrySelected.makeFetchTransport,
    stackParser: sentrySelected.defaultStackParser,
    release,
    ...sentryOptions,
    beforeSend: combinedBeforeSend,
    beforeSendSpan: defaultBefore,
    beforeSendTransaction: defaultBefore,
  }

  if (dsn || !isSentryInitialized) {
    initSentry(preparedSentryOptions, router)
  }

  const { integrations } = createIntegration(sentrySelected, router)

  const client = new sentrySelected.BrowserClient({
    integrations: [...integrations],
    ...preparedSentryOptions,
  })
  const scope = new sentrySelected.Scope()
  scope.setClient(client)
  const restaurantId = getRestaurantId()

  scope.setTag('restaurantId', restaurantId)
  if (packageName) {
    scope.setTag('package', packageName)
  }

  return { client, scope }
}
