import * as Sentry from '@sentry/vue'
import { makeSentryScope, LEVEL } from './services/sentry'

export default class Logger {
  #sentryScope

  /**
   * @param  {string} [packageName=''] will be prepended to data for each Logger call
   * @param  {string} [release = '0.0.1'] will be prepended to data for each Logger call
   * @param  {Object} [sentryOptions={}] allows to use custom options for sentry
   * @param  {Object} [router] allows to capture Vue routing events in logger
   */
  constructor({
    packageName = '',
    release = '0.0.1',
    sentryOptions = {},
    router,
  } = {}) {
    const { scope } = makeSentryScope({
      packageName,
      release,
      sentryOptions,
      router,
    })
    this.#sentryScope = scope
  }

  /**
   * Log an error
   * @param  {object} error - The actual original exception
   * @param  {object|null} [payload={}] - Any additional properties you want to add to the logs
   */
  error(error = '', payload = null) {
    const clonedScope = this.#sentryScope.clone()
    Sentry.withScope(clonedScope, () => {
      clonedScope.setExtras(payload)
      clonedScope.setLevel(LEVEL.Error)
      clonedScope.captureException(error)
    })
  }

  /**
   * Log info
   * @param  {string} message - Describe what happened
   * @param  {object|null} [payload] - Any additional properties you want to add to the logs
   */
  info(message, payload = null) {
    const clonedScope = this.#sentryScope.clone()
    Sentry.withScope(clonedScope, () => {
      clonedScope.setExtras(payload)
      clonedScope.setLevel(LEVEL.Info)
      clonedScope.captureMessage(message)
    })
  }
}
