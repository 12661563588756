import { FEATURE_TOGGLE_STORAGE_KEY_PREFIX } from '../constants'

function getFeatureToggleStorageKey(featureName) {
  return `${FEATURE_TOGGLE_STORAGE_KEY_PREFIX}${featureName}`
}

/**
 * Gets the state of feature toggle in local storage.
 * Local storage feature toggle should have key of format `je.pcweb.feature_{featureName}` and value either `enabled` or `disabled`
 * @param  {string} featureName - name of the feature toggle
 * @return {boolean|null} Returns `true` if feature is enabled in local storage, returns `false` if feature is disabled in local storage, returns `null` if feature has no valid configuration in local storage
 */
export function getIsFeatureEnabledInLocalStorage(featureName) {
  const featureToggleState = localStorage.getItem(
    getFeatureToggleStorageKey(featureName)
  )

  if (featureToggleState === 'enabled') return true
  if (featureToggleState === 'disabled') return false
  return null
}
