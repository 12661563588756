import Logger from './Logger'

export default {
  async install(Vue, { packageName = '', release, sentryOptions = {} }) {
    Vue.prototype.$logger = new Logger({
      packageName,
      release,
      sentryOptions,
    })
  },
}
