import mitt from 'mitt'
import eventsList from './eventsList'

export default class EventBus {
  constructor() {
    this.emitter = mitt()
    this.list = eventsList

    this.on = this.emitter.on
    this.emit = this.emitter.emit
    this.off = this.emitter.off
  }
}
